@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300; 400;500;700&display=swap");

body { 
  font-family: "Poppins", sans-serif;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
}

html{
  line-height:1.15;
  -webkit-text-size-adjust:100%
}
 
.overlay {
  position: fixed;
  height: 100%;    
  top: 15;
  right: 0; 
  padding-right: 1%;
  padding-top: 1%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 9999;  
  opacity: 2.0 - 4.0;
  
} 
.mobil {
  width: 100%;
  height: 200px;   
  align-items: center; 
  top: 0;
  right: 0; 
   
 
} 
 
/* Menu nav */

.c-main-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-right: 30px;
  position: relative;
  color: #fff;
  
}
.c-main-nav__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  border-right: 1px solid rgba(112, 112, 112, 0.35);
}
.c-main-nav__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 6, 21, 0.9);
  z-index: 10;
  display: none;
  padding: 100px 45px 0 45px;
}
.c-main-nav__container .o-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.c-main-nav__item {
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 20px;
  
}
.c-main-nav__item:last-child {
  padding-bottom: 0;
}
.c-main-nav__item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  position: relative;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  padding-right: 80px;
  text-decoration: none;
}
.c-main-nav__item a:before {
  content: "";
  position: absolute;
  width: 42px;
  height: 16px;
  background: url(../src/assets/img/arrow-right-icon.svg) no-repeat;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.c-main-nav__item a:hover {
  text-decoration: none;
}
.c-main-nav__item a:hover:before {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}
.c-main-nav__item .sub-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display:none;
  position: absolute;
  top: 0;
  right: calc(-400px - 100px);
  width: 400px;
  font-size: 25px;
  font-weight: normal;
}
.c-main-nav__item .sub-menu li {
  margin-bottom: 25px;
}
.c-main-nav__item .sub-menu li a {
  padding-right: 0;
  text-decoration: none;
}
.c-main-nav__item .sub-menu li a:before {
  display: none;
}
  @media (max-width: 1366px) {
  .c-main-nav__item {
    font-size: 36px;
    padding-bottom: 20px;
  }
  .c-main-nav__item .sub-menu {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1200px) {
  .c-main-nav {
    padding-right: 80px;
  }
  .c-main-nav__item {
    font-size: 26px;
    padding-bottom: 25px;
  }
  .c-main-nav__item .sub-menu {
    right: calc(-300px - 80px);
    font-size: 18px;
  }
  .c-main-nav__item .sub-menu li {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .c-main-nav__container {
    padding-top: 0;
  }
  .c-main-nav__item {
    font-size: 24px;
    padding-bottom: 15px;
  }
  .c-main-nav__item .sub-menu {
    right: calc(-250px - 60px);
    width: 250px;
  }
  .c-main-nav__item .sub-menu li {
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .c-main-nav {
    width: 100%;
    padding-right: 0;
    margin-top: 80px;
    height: 400px;
    overflow: scroll;
  }
  .c-main-nav__wrapper {
    border-right: 0;
    width: 100%;
  }
  .c-main-nav__item {
    font-size: 24px;
  }
  .c-main-nav__item a:before {
    display: none;
  }
  .c-main-nav__item a:after {
    display: block;
  }
  .c-main-nav__item .sub-menu {
    position: relative;
    right: initial;
    top: initial;
    width: 100%;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .c-main-nav__item .sub-menu li {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .c-main-nav {
    margin-top: 60px;
  }
  .c-main-nav__container {
    padding: 0;
  }
  .c-main-nav__item {
    font-size: 20px;
  }
  .c-main-nav .sub-menu {
    position: relative;
    right: initial;
    top: initial;
    width: 100%;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .c-main-nav .sub-menu li {
    margin-bottom: 15px;
  }
}  


.u-icon{
  fill:#000
}

.u-relative{
  position:relative
}

.page-content__hr{
  margin:60px 0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.page-content__hr ul{
  display:inline-block;
  width:35%;
  /* float: left;  */
}
.page-content__hr .img-wrapper{
  display:inline-block;
  width:60%;
  margin-left:5%
}
  @media (max-width: 768px){
  .page-content__hr{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  .page-content__hr ul{
      display:block;
      width:100%
  }
  .page-content__hr .img-wrapper{
      display:block;
      width:100%;
      margin:0
  }
  .page-content__hr .img-wrapper img{
      margin:0 auto;
      display:block
  }
} 
.page-content h1,.page-content h2,.page-content h3,.page-content h4,.page-content h5,.page-content h6{
  margin-top:30px;
  margin-bottom:20px;
  color:#002B49; 
  text-decoration: none;
}
.page-content h1{
  font-size:40px;
  color: #ff7e00;
}
.page-content h2 a{
  text-decoration: none;
}
.page-content h2{
  font-size:30px;
  color: #ff7e00;  
}
.page-content h3{
  font-size:27px
}
.page-content h4{
  font-size:23px; 
}
.page-content h5{
  font-size:20px
}
.page-content h6{
  font-size:17px
}
.page-content p{
  font-size:16px;
  line-height:27px;
  letter-spacing:0.015rem
}
.page-content .c-button{
  height:45px;
  font-weight:500;
  padding:0 30px;
  margin-top:20px; 
}
.page-content small{
  color:#80909C
}
.page-content img{
  margin-bottom:30px
}
.page-content img.align-right{
  float:right;
  margin-left:30px;
  margin-bottom:30px;
  max-width:60%
}
/* @media (max-width: 480px){
  .page-content img.align-right{
      max-width:40%
  }
} */
.page-content ol{
  margin:30px 0;
  padding:0;
  counter-reset:item
}
.page-content ol li{
  margin:0 0 15px 0;
  padding:0;
  list-style-type:none;
  counter-increment:item
}
.page-content ol li:before{
  display:inline-block;
  padding-right:10px;
  font-weight:bold;
  text-align:right;
  content:counter(item) "."
}


.bg {
  width: 100vw;
  height: 100vh; 
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform; 
}

.bg.bg1 {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  object-fit: cover; 
  width: 100vw;
  height: 100vh;  
  
} 

.bg.bg2 {
  /* background-image: url(./assets/img/slider/slide_test.jpg);  */
  background-color: #fff;
  width: 100vw;
  height: 100vh;
}

.slide-image-right {
  position: absolute;
  right: 0%; 
  width: 100vw;
  height: 100vh;
}
.slide-image-right img{
  position: absolute;
  /* width: 600px;  */
  height: 100vh;
  /* right: -20%; */
  object-fit: cover; 
}

.slide-image-left{
  position: absolute;
  left: 0%; 
  width: 100vw;
  height: 100vh;
}
.slide-image-left img{
  position: absolute;
  /* width: 600px;  */
  width: 40vw;
  height: 40vh;
  left: 0%;
  top: 5%;
  object-fit: cover; 
}

.slide-image-left2{
  position: absolute;
  left: 0%; 
  width: 100vw;
  height: 100vh;
}
.slide-image-left2 img{
  position: absolute;
  /* width: 600px;  */
  width: 40vw;
  height: 60vh;
  left: 0%;
  bottom: 0%;
  /* object-fit: cover;  */
}

   
.bg.bg3 {
  background-image: url(./assets/img/slider/slide_b_2.jpg);
}
.bg.bg4 {
  background-image: url(./assets/img/slider/slide_b_3.jpg);
}
.bg.bg5 {
  background-image: url(./assets/img/slider/slide_b_4.jpg);
}
.bg.bg6 {
  background-image: url(./assets/img/slider/slide_b_5.jpg);
}


/* Contact */
form .form-column{
  width:100%;
  margin-right:30px
}
form .form-column:last-child{
  margin-right:0
}
form .form-column__row{
  display:-webkit-box;
  display:-ms-flexbox;
  display:block;
 
  
}
form .form-group{
  position:relative;
  font-size:12px;
  margin-bottom:25px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column
}
form .form-group label{
  font-weight:500;
  margin-bottom:10px
}
form .form-group input,form .form-group select{
  height:40px;
  border:1px solid #ff7e00;
  padding:10px 20px;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  width:100%;
  background-color:#F8FBFD;
  border-radius:3x
} 
form .form-group input:focus,form .form-group select:focus{
  outline:none;
  
}
form .form-group textarea{
  border:1px solid #ff7e00;
  padding:10px 20px;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  height:125px;
  width:100%;
  border-radius:9px;
  background-color:#F8FBFD
}
form .form-group textarea:focus{
  outline:none
}
form .form-group--col{
  position:relative;
  width:50%;
  margin-left:30px
}
form .form-group--col:first-child{
  margin-left:0
}
form .c-button{
  font-weight:500;
  font-size:14px;
  cursor:pointer;
  width:160px; 
}
/* @media (max-width: 768px){
  form .form-column{
      width:100%;
      margin-right:0
  }
  form .form-column:last-child{
      margin-right:0
  }
  form .form-column__row{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  form .form-group{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column;
      margin-bottom:30px
  }
  form .form-group input,form .form-group select{
      height:45px;
      padding:5px 15px;
      margin:0
  }
  form .form-group--col{
      position:relative;
      width:100%;
      margin-left:0;
      margin-bottom:20px
  }
  form .form-group--col:last-child{
      margin-bottom:0
  }
  form .c-button{
      width:100%
  }
} */
.c-contact .c-tabs__nav ul{
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.c-contact__item:first-child .c-contact__item__wrapper{
  border-top:1px solid #E5E5E5
}
.c-contact__item__wrapper{
  background:#fff;
  border:1px solid #E5E5E5;
  border-top:0; 
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex; 
}
.c-contact__item--map{
  display:grid
}
.c-contact__item--map-container iframe{
  width:100%;
  height:450px
}
.c-contact__item__left{
  width:150px
}
.c-contact__item__left .image{
  width:150px;
  height:150px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.c-contact__item__right{
  width:calc(100% - 150px);
  padding-left:40px;
  font-size:14px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.c-contact__item__right .col{
  width:180px
}
.c-contact__item__right .col--first{
  width:calc(100% - 180px)
}
.c-contact__item__right .content .title{
  font-weight:700;
  margin-bottom:20px
}
.c-contact__item__right .content .option--address{
  margin-bottom:20px
}
.c-contact__item__right .content .option span{
  display:inline-block;
  min-width:150px;
  font-weight:700
}
.c-contact__item__right .content .option span:after{
  content:":";
  float:right
}
.c-contact__item__right .content__row{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start
}
.c-contact__item__right .content--col{
  margin-right:20px
}
.c-contact__item__right .content--col:last-child{
  margin-right:0
}
.c-contact__item__right .actions{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end
}
.c-contact__item__right .actions .js-show-map{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  cursor:pointer
}
.c-contact__item__right .actions .js-show-map span{
  color:#0083BF;
  font-weight:500;
  margin:0 15px;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s
}
.c-contact__item__right .actions .js-show-map svg{
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s
}
.c-contact__item__right .actions .js-show-map svg.pin{
  fill:#0083BF;
  width:14px;
  height:18px
}
.c-contact__item__right .actions .js-show-map svg.arrow{
  fill:#0083BF;
  width:12px;
  height:8px
}
.c-contact__item__right .actions.js-active .js-show-map span{
  color:#052B49
}
.c-contact__item__right .actions.js-active .js-show-map svg{
  fill:#052B49
}
.c-contact__item__right .actions.js-active .js-show-map svg.arrow{
  -webkit-transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  transform:rotate(180deg)
}
.c-contact .form-actions{
  text-align:center
}
.c-contact__form-title{
  font-size:30px;
  font-weight:700;
  margin-top:100px;
  margin-bottom:30px
}
.c-contact__form-desc{
  margin-bottom:80px;
  font-size:15px
}
/* @media (max-width: 1024px){
  .c-contact__item__right .content .option{
      margin-bottom:10px
  }
  .c-contact__item__right .content__row{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  .c-contact__item__right .content--col{
      margin-right:0
  }
  .c-contact__item__right .content--col:last-child{
      margin-right:0
  }
} */
.c-contact__companies .industry__nav{
  width:100%
}
.c-contact__companies .industry__nav .mCSB_container{
  margin-bottom:17px !important
}
.c-contact__companies .industry__nav ul{
  width:1170px;
  overflow:hidden;
  border:1px solid #E5E5E5;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}
.c-contact__companies .industry__nav ul li{
  background:none;
  padding:0;
  margin:0;
  width:100%
}
.c-contact__companies .industry__nav ul li button{
  font-size:14px;
  height:45px;
  width:100%;
  color:#80909C;
  background-color:#fff;
  border:none;
  border-right:1px solid #E5E5E5;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s
}
.c-contact__companies .industry__nav ul li button.jplist-selected{
  background-color:#0083BF;
  color:#fff
}
.c-contact__companies .industry__nav ul li:last-child button{
  border-right:0
}
.c-contact__companies .c-no-result{
  padding-top:40px
}
.c-contact__companies .c-pagination{
  margin-top:40px
}
/* @media (max-width: 768px){
  .c-contact__item__wrapper{
      padding:20px
  }
  .c-contact__item__right{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  .c-contact__item__right .col{
      width:100%
  }
  .c-contact__item__right .col--first{
      width:100%;
      margin-bottom:20px
  }
  .c-contact .c-tabs--page .c-tabs__nav ul li:nth-child(1) a,.c-contact .c-tabs--page .c-tabs__nav ul li:nth-child(2) a{
      border-bottom:1px solid #E5E5E5
  }
}
@media (max-width: 576px){
  .c-contact__item__left{
      width:100px
  }
  .c-contact__item__left .image{
      width:100px;
      height:100px
  }
  .c-contact__item__right{
      width:calc(100% - 100px);
      padding-left:30px
  }
  .c-contact__item__right .actions{
      -webkit-box-pack:start;
      -ms-flex-pack:start;
      justify-content:flex-start
  }
  .c-contact__item__right .col{
      width:100%
  }
  .c-contact__item__right .col--first{
      width:100%;
      margin-bottom:20px
  }
}
@media (max-width: 480px){
  .c-contact__item__wrapper{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  .c-contact__item__left{
      width:100%;
      margin-bottom:30px
  }
  .c-contact__item__left .image{
      width:100px;
      height:100px
  }
  .c-contact__item__right{
      width:100%;
      padding-left:0
  }
} */

/* History */

.c-history__list{
  max-width:850px;
  width:100%;
  margin:0 auto
}
.c-history__item{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  margin-bottom:30px
}
.c-history__date{
  font-size:36px;
  font-weight:200;
  color:#3D271A;
  width:120px;
  height:120px;
  border-radius:50%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  border:3px solid #ff7e00;
  margin-bottom:30px;
  background:#fff
}
.c-history__show-more{
  width:100%;
  text-align:center
}
.c-history__show-more .c-button{
  height:45px;
  cursor:pointer;
  font-size:14px;
  font-weight:500;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s
}
.c-history__show-more .c-button svg{
  fill:#fff;
  width:12px;
  height:12px;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s;
  margin-left:15px
}
.c-history__container{
  width:100%;
  margin-bottom:5px;
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex; 
}
.c-history__container--reverse{
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end
}
.c-history__container--reverse .c-history__content{
  -webkit-box-align:end;
  -ms-flex-align:end;
  align-items:flex-end
}
.c-history__container--reverse .c-history__content .image--container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end
}
.c-history__content{
  width:60%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  border-color: #ff7e00;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
}
.c-history__content .image{
  width:75%
}
.c-history__content .image--container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  width:100%;
  position:relative;
  margin-bottom:20px
}
.c-history__content .image--container::before{
  content:"";
  position:absolute;
  top:50%;
  width:100%;
  height:1px;
  background:#E5E5E5;
  z-index:1;
  left:0
}
.c-history__content .image img{
  position:relative;
  z-index:2;
  width:100%
}
.c-history__content .desc{
  font-size:16px;
  line-height:25px;
  width:75%;
  letter-spacing:0.015rem
}
/* @media (max-width: 768px){
  .c-history__content .image{
      width:90%
  }
  .c-history__content .desc{
      width:90%
  }
}
@media (max-width: 480px){
  .c-history__container{
      margin-bottom:50px
  }
  .c-history__content{
      width:100%
  }
  .c-history__content .image{
      width:100%
  }
  .c-history__content .image--container--container::before{
      display:none
  }
  .c-history__content .desc{
      width:100%
  }
} */



/* button  */


.c-button{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  padding:0 15px;
  margin:0;
  height:50px;
  font:inherit;
  color:#fff;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  cursor:pointer;
  border-radius:3px;
  -webkit-transition:all 0.3s ease;
  -o-transition:all 0.3s ease;
  transition:all 0.3s ease;
  background:#ff7e00;
  text-decoration:none;
  border:1px solid #ff7e00
}
.c-button:hover{
  color:#000;
  background:#ff7e00;
  border:1px solid #ff7e00;
  text-decoration:none
}
.c-button--md{
  width:225px
}
.c-button--border{
  border:1px solid #ff7e00;
  background-color:transparent;
  color:#ff7e00
}
.c-button--white{
  border-color:#fff;
  color:#fff;
  background-color: #fff;
}
.c-button--white:hover{
  border:1px solid #ff7e00;
  background-color:#fff
  
}
.c-button--secondary{
  background:#3D271A
}

/* subheader */

.c-sub-header__wrapper{
  position:relative;
  background-color: #484242ae;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  height:400px;
  padding:0 45px 0 45px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start
}
.c-sub-header__wrapper::before{
  content:"";
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.1)
}
.c-sub-header__content{
  position:relative;
  z-index:1;
  color:#fff
}
.c-sub-header--title{
  font-size:63px;
  font-weight:300;
}
.c-sub-header--desc{
  font-size:19px;  
} 
.c-sub-header--link{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end;
}
.c-sub-header--link a{
  color:#fff;
  border:1px solid #fff;
  border-radius:10px;
  padding:5px 10px;
  font-size:12px;
  -webkit-transition:all 0.5s;
  -o-transition:all 0.5s;
  transition:all 0.5s;
  
}
.c-sub-header--link a:hover{
  background:#fff;
  color:#002B49
}
.c-sub-header--page .c-sub-header__content{
  margin-top:50px
}
.c-sub-header--page .c-sub-header--sub-title{
  font-size:12px;
  font-weight:700;
  letter-spacing:0.370rem;
  margin-left:3px
}
.c-sub-header--page .c-sub-header--title{
  font-size:40px;
  font-weight:700;
  line-height:60px;
  margin-top:250px;
  margin-left:-120px;

}
.c-sub-header--title-brands{
  display:none;

}
/* @media (max-width: 1200px){
  .c-sub-header__wrapper{
      height:300px;
      -webkit-box-align:end;
      -ms-flex-align:end;
      align-items:flex-end;
      padding:0 45px 30px 45px
  }
  .c-sub-header--title{
      font-size:40px
  }
}
@media (max-width: 1024px){
  .c-sub-header__wrapper{
      height:250px
  }
  .c-sub-header--title{
      font-size:32px
  }
  .c-sub-header--desc{
      font-size:16px;
      text-decoration: none;
      color: #fff;
  }
}
@media (max-width: 768px){
  .c-sub-header--sub-img{
      display:none
  }
  .c-sub-header--desc{
      display:none
  }
  .c-sub-header--title-brands{
      display:block
  }
  .c-sub-header--link{
      -webkit-box-pack:start;
      -ms-flex-pack:start;
      justify-content:flex-start
  }
}
@media (max-width: 480px){
  .c-sub-header__wrapper{
      padding:0 0 30px 0
  }
  .c-sub-header--page .c-sub-header--title{
      margin-top:0;
      line-height:100%;
      font-size:24px
  }
} */


/* bread chump */

.o-breadcrumb{
  border-bottom:1px solid #E5E5E5
}
.o-breadcrumb__list{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:60px
}
.o-breadcrumb__wrapper a{
  font-size:13px;
  color:#80909C;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:100%;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.o-breadcrumb__wrapper a::after{
  position:relative;
  top:11px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  height:20px;
  margin:0 20px;
  content:url("assets/img/breadcrumb-icon.svg");
  -webkit-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  transform:translateY(-50%)
}
.o-breadcrumb__wrapper a:hover{
  text-decoration:none
}
.o-breadcrumb__wrapper a.active{
  color:#0083BF
}
.o-breadcrumb__wrapper a.active::after{
  display:none
}
/* @media (max-width: 768px){
  .o-breadcrumb{
      display:none
  }
} */

/* Tanıtım */

.c-know-us {
  padding: 50px 0;
}
.c-know-us__wrapper {
  position: relative;
  z-index: 1;
}
.c-know-us__item {
  padding: 0 0;
}
.c-know-us__actions {
  padding-top: 1px;
  text-align: center;
}
.c-know-us__actions .c-button { 
  width: 50px;
  font-size: 14px;
  font-weight: 500;
}
.c-know-us .c-icon-box__title {
  position: relative;
}
.c-know-us .c-icon-box__title:before {
  content: "";
  position: absolute;
  top: 12px;
  left: -40px;
  width: 1px;
  height: 38px;
  background-color: #ff7e00;
}
/* @media (max-width: 1200px) {
  .c-know-us .c-icon-box__title:before {
    top: 8px;
    height: 23px;
  }
}
@media (max-width: 768px) {
  .c-know-us {
    padding: 50px 0;
  }

}
@media (max-width: 576px) {
  .c-know-us__item {
    padding: 0 20px;
  }
  .c-know-us .c-icon-box__title:before {
    left: -20px;
  }
} */
  .c-know-us__item-container {
    margin-bottom: 50px;
    width: 95%;
    margin: auto;
  }
/* Container */
.o-header{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  background:transparent;
  z-index:11
}
/* .o-header.js-fixed{
  position:fixed
} */
.o-header__top{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  height:90px;
  padding:15px 60px;
  background-color:transparent
}
.o-header__logo{
  position: relative; 
  height:70px;
  width:1px;
  top: 45%; 
  left: 85%;
  /* logo yeri burdan ayarladık */
}
.LogoWidth{
  width: 200px;
}
/* ekran 1200 */
@media (max-width: 1200px) { 
  .LogoWidth{
    width: 180px;
  }
.o-header__logo{
  position: relative; 
  height:70px;
  width:80px;
  top: 10%; 
  left: 70%;
  /* logo yeri burdan ayarladık */
} 
 
.signal-container{
  position:absolute;
  width:75px;
  height:75px; 
}
.signal-container .signal-element,.signal-container .signal-element-2{
  position:absolute;
  left:180%;
  top:120%;
  -webkit-transform:translate(-50%, -50%);
  -ms-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%);
  width:60px;
  height:60px;
  opacity:1;
  background:transparent;
  border-radius:50%;
  border:6px solid #fff;
  -webkit-animation:heartbeat 3s infinite;
  animation:heartbeat 3s infinite;
  -webkit-transition-timing-function:ease-in;
  -o-transition-timing-function:ease-in;
  transition-timing-function:ease-in
}
.logo-top-wrapper{
  position:relative;
  overflow:hidden;
  width:250px;
  height:100px;
  top:60px;
  left:-10px
}
}
 
@media (max-width: 1024px) { 
  .LogoWidth{
    width: 160px;
  }
  .o-header__logo{
    position: relative; 
    height:70px;
    width:80px;
    top: 20%; 
    left: 75%;
    /* logo yeri burdan ayarladık */
  } 
  .c-full-slider__bullet{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    cursor:pointer;
    margin:0 10px;
    width:10px;
    height:10px;
    border-radius:50%;
    -webkit-transition:all ease .3s;
    -o-transition:all ease .3s;
    transition:all ease .3s;
    position:relative;
    z-index:1;
    background-color: #fff;
  }
  
  }

  @media (max-width: 800px) { 
    .LogoWidth{
      width: 130px;
    }
    .o-header__logo{
      position: relative; 
      height:70px;
      width:80px;
      top: 20%; 
      left: 70%;
      /* logo yeri burdan ayarladık */
    } 
    .mySwiper .swiper-slide-thumb-active {  
      display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    cursor:pointer;
    margin:0 10px;
    width:10px;
    height:10px;
    border-radius:50%;
    -webkit-transition:all ease .3s;
    -o-transition:all ease .3s;
    transition:all ease .3s;
    position:relative;
    z-index:1;
    background-color: #000;
    }
     
    
    }

.o-header__nav .c-nav{
  padding:0;
  margin:0;
  list-style:none;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.o-header__nav .c-nav .c-submenu{
  display:none
}
.o-header__nav .c-nav__item{
  font-size:14px;
  font-weight:500;
  margin-left:35px
}
.o-header__nav .c-nav__item:first-child{
  margin-left:0
}
.o-header__nav .c-nav__item a{
  color:#fff
}
.o-header__nav .c-nav__item a:hover{
  color:#fff;
  text-decoration:none
} 
.o-header__nav .c-nav__item--icon svg{
  max-width:20px;
  max-height:20px
} 
.o-header__nav .c-nav__item--menu-trigger{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;  
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  cursor:pointer
}
.o-header__nav .c-nav__item--menu-trigger .title{
  font-size:13px;
  color:#fff;
  margin-right:10px;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s
}
.o-header__nav .c-nav__item--menu-trigger.js-open .title{
  opacity:0
}
.o-header__nav .c-nav__item--menu-trigger.js-open .o-nav-icon{
  height:32px
}
.o-header__nav .o-nav-icon{
  width: 36px;
  height:20px;
  color: #000; 
  position:absolute;
  left: 40px;  
  top: 25px;

  -webkit-transform:rotate(0);
  -ms-transform:rotate(0);
  transform:rotate(0);
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s;
  cursor:pointer
}

.o-header__nav .o-nav-icon-bayi{
  width:150px;
  height:15px; 
  color: #F9B224;
  position:absolute;
  top: 10px;
  right: 1px;
  -webkit-transform:rotate(0);
  -ms-transform:rotate(0);
  transform:rotate(0);
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s;
  cursor:pointer
}
.o-header__nav .o-nav-icon span{
  position:relative;
  width:36px;
  height:3px;
  display:block;
  background:#fff;
  opacity:1;
  right:0;
  -webkit-transform:rotate(0);
  -ms-transform:rotate(0);
  transform:rotate(0);
  border-radius:3px;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s
}
.o-header__nav .o-nav-icon span:nth-child(1){
  top:0
}
.o-header__nav .o-nav-icon span:nth-child(2){
  position:absolute;
  top:50%;
  -webkit-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  transform:translateY(-50%)
}
.o-header__nav .o-nav-icon span:nth-child(3){
  bottom:0
}
.o-header__nav .o-nav-icon.js-open span:nth-child(1){
  top:50%;
  -webkit-transform:rotate(135deg);
  -ms-transform:rotate(135deg);
  transform:rotate(135deg)
}
.o-header__nav .o-nav-icon.js-open span:nth-child(2){
  left:-60px;
  opacity:0
}
.o-header__nav .o-nav-icon.js-open span:nth-child(3){
  top:50%;
  -webkit-transform:rotate(-135deg);
  -ms-transform:rotate(-135deg);
  transform:rotate(-135deg)
}
 

.o-section 
{
  display: block;
  unicode-bidi: isolate; 
  height: 100vh; /* For 100% screen height */
  width:  100vw; /* For 100% screen width */ 
}
 
/* animated logo
 */
 

 .c-animated-logo{
  position:absolute;
  right:1%;
  top:10%;
  width:20px;
  height:20px;
  -webkit-animation:move 1s forwards;
  animation:move 1s forwards;
  -webkit-transition-timing-function:linear;
  -o-transition-timing-function:linear;
  transition-timing-function:linear;
  -webkit-animation-delay:2s;
  animation-delay:2s
/*   
  position:absolute;
  right:820%;
  top:10%;
  width:30px;
  height:30px;
  -webkit-animation:move 1s forwards;
  animation:move 1s forwards;
  -webkit-transition-timing-function:linear;
  -o-transition-timing-function:linear;
  transition-timing-function:linear;
  -webkit-animation-delay:2s;
  animation-delay:2s */
}
.main-logo-container{
  position:relative;
    top:0;
    right:0
}
.signal-container{
  position:absolute;
  width:175px;
  height:175px; 
}
.signal-container .signal-element,.signal-container .signal-element-2{
  position:absolute;
  left:50%;
  top:50%;
  -webkit-transform:translate(-50%, -50%);
  -ms-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%);
  width:160px;
  height:160px;
  opacity:1;
  background:transparent;
  border-radius:50%;
  border:6px solid #ff7e00;
  -webkit-animation:heartbeat 3s infinite;
  animation:heartbeat 3s infinite;
  -webkit-transition-timing-function:ease-in;
  -o-transition-timing-function:ease-in;
  transition-timing-function:ease-in
}
.signal-container .signal-element-2{
  -webkit-animation-delay:1.5s;
  animation-delay:1.5s
}

.bottom-container{
  position:absolute; 
}
.logo-top-wrapper{
  position:relative;
  overflow:hidden;
  width:300px;
  height:100px;
  top:60px;
  left:-50px
}
.logo-top-wrapper #logo-top{
  position:absolute;
  left:10%;
   top:50%;  
    -webkit-transform:translate(-50%, -50%);
  -ms-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%);  
  width: 60px;
  height: 60px;
  opacity:0;
  -webkit-animation:main-logo 3s forwards;
  animation:main-logo 3s forwards;
  -webkit-transition-timing-function:ease-in;
  -o-transition-timing-function:ease-in;
  transition-timing-function:ease-in;
  -webkit-animation-delay:0.5s;
  animation-delay:0.5s
}
.bottom-wrapper{
  position:relative;
  top:-100px
}
 
 @-webkit-keyframes main-logo{
  20%{
      opacity:1
  }
  50%{
      width:100px;
      height:100px;
      opacity:1
  }
  100%{
      opacity:1
  }
} 
@keyframes main-logo{
  20%{
      opacity:1
  }
  50%{
      width:100px;
      height:100px;
      opacity:1
  }
  100%{
      opacity:1
  }
}
@-webkit-keyframes slide-bottom{
  100%{
      bottom:0
  }
}
@keyframes slide-bottom{
  100%{
      bottom:0
  }
}
@-webkit-keyframes slide-top{
  100%{
      top:0
  }
}
@keyframes slide-top{
  100%{
      top:0
  }
}
@-webkit-keyframes width-left{
  100%{
      width:100px
  }
}
@keyframes width-left{
  100%{
      width:100px 
  }
}
@-webkit-keyframes heartbeat{
  0%{
      opacity:1
  }
  100%{
      opacity:0;
      width:150px;
      height:150px
  }
}
@keyframes heartbeat{
  0%{
      opacity:1
  }
  100%{
      opacity:0;
      width:450px;
      height:450px
  }
}
@-webkit-keyframes move{
  100%{
      right:10%;
      top:0
  }
}
@keyframes move{
  100%{
      right:10%;
      top:0
  }
} 
/* @media screen and (min-width: 1600px){
  c-animated-logo{
    right:550%;
    top:10%;
    -webkit-animation:move 1s forwards;
    animation:move 1s forwards;
    -webkit-transform:scale(0.7);
    -ms-transform:scale(0.7);
    transform:scale(0.7)
}
@-webkit-keyframes move{
    100%{
        right:24%;
        top:-40px
    }
}
@keyframes move{
    100%{
        right:24%;
        top:-40px
    }
}
}
@media screen and (min-width: 1400px){
  @-webkit-keyframes move{
      100%{
          right:25%;
          top:-30px
      }
  }
  @keyframes move{
      100%{
          right:25%;
          top:-30px
      }
  }
}
@media screen and (min-width: 1200px){
  .c-animated-logo{
      right:30%;
      top:40%;
      -webkit-animation:move 1s forwards;
      animation:move 1s forwards;
      -webkit-transform:scale(0.6);
      -ms-transform:scale(0.6);
      transform:scale(0.6)
  }
  @-webkit-keyframes move{
      100%{
          right:25%;
          top:-30px
      }
  }
  @keyframes move{
      100%{
          right:25%;
          top:-30px
      }
  }
}
@media screen and (max-width: 991px){
  .c-animated-logo{
    right:30%;
      top:40%;
      -webkit-transform:scale(0.6);
      -ms-transform:scale(0.6);
      transform:scale(0.6)
  }
  @-webkit-keyframes move{
      100%{
          left:55%;
          top:-30px
      }
  }
  @keyframes move{
      100%{
          left:55%;
          top:-30px
      }
  }
}
@media (max-width: 768px){
  .c-animated-logo{
    right:30%;
      top:40%;
      -webkit-transform:scale(0.6);
      -ms-transform:scale(0.6);
      transform:scale(0.6)
  }
  @-webkit-keyframes move{
      100%{
          right:0%;
          top:-1px
      }
  }
  @keyframes move{
      100%{
          right:90%;
          top:-30px
      }
  }
  .main-logo-container{
    position:relative;
      top:0px;
      right:-10%;
  }
} */
 


/* Slider */

.no-js .c-full-slider{
  min-height:900px
}
.c-full-slider .swiper-container{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  margin-left:auto;
  margin-right:auto
}
.c-full-slider .swiper-slide{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.c-full-slider__item:before{
  content:"";
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:0;
  background:rgba(6,6,21,0.5);
  background:-webkit-radial-gradient(circle, rgba(6,6,21,0.1) 0%, rgba(6,6,21,0.7) 60%, rgba(6,6,21,0.9) 100%);
  background:-o-radial-gradient(circle, rgba(0, 0, 255, 0.1) 0%, rgba(6,6,21,0.7) 60%, rgba(6,6,21,0.9) 100%);
  /* background:radial-gradient(circle, rgba(145, 145, 145, 0.1) 0%, rgba(6,6,21,0.7) 60%, rgba(6,6,21,0.9) 100%);  */
}
.c-full-slider__content{
  position:relative;
}
.c-full-slider__caption{
   padding-top: 250px;
}
.c-full-slider__caption-text{ 
  max-width: calc(100% - 100px);
  margin-top: -1px;
}
.c-full-slider__cat{
  font-size:16px;
  font-weight:700;
  letter-spacing:8px;
  color:#F9B224;
  top: 10px;
  
}
.c-full-slider__cat span{
  display:inline-block;
  margin-bottom:45px
}
.c-full-slider__title{
  font-size:40px;
  font-weight:700;
  color:#fff;
  margin-bottom:55px; 
}
.c-full-slider__desc{
  font-size:25px;
  font-weight: 800;
  color:#fff
}
.c-full-slider__actions{
  /* text-align:right;   */
  /* margin-right: 120px; */
   
  margin: 0;
  position: absolute;
  top: 70%;
  left: 90%;
  transform: translate(-50%, -50%);
   
}
.c-full-slider__actions .c-button{
  width:200px;
  font-size:14px;
  font-weight:500;  
  border-color: #F9B224;
  color: #F9B224;
  border-radius: 5px;
}
.c-full-slider__actions .c-button .a{
  width:200px;
  font-size:14px;
  font-weight:500;
  background-color:#F9B224;
  color: #000; 
  border-radius: 5px;
}
.c-full-slider__actions .c-button .a:hover{
  width:200px;
  font-size:14px;
  font-weight:500; 
  color: #000;
}
/*  Slider BUllet*/

.mySwiper .swiper-slide-thumb-active {  
  all: initial;
  * {
    all: unset; 
    display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  /* cursor:pointer; */
  margin:0 20px;
  width:80px;
  height:80px;
  border-radius:50%;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s;
  position:relative;
  z-index:1;
  /* background-color: #fff; */

  }
  
  /* Swiper Slider Thumb Ayarlanacak */
}
 
.c-full-slider__bullets{
  position:absolute;
  width:100%;
  height:110px;
  bottom:20px;
  left:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  z-index:1;
  /* background-color: #fff; */
}
.c-full-slider__bullets__wrapper{
  width:70%;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin: auto;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.c-full-slider__bullet{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  cursor:pointer;
  margin:0 10px;
  width:80px;
  height:80px;
  border-radius:50%;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s;
  position:relative;
  z-index:1;
  background-color: #fff;
}
.no-js .c-full-slider__bullet{
  display:none
}
.c-full-slider__bullet:hover,.c-full-slider__bullet.js-active{
  background-color:#fff;
  width:80px;
  height:80px
}
.c-full-slider__bullet:hover svg,.c-full-slider__bullet.js-active svg{
  fill:#3D271A
}
.c-full-slider__bullet:hover+.title{
  /* position: absolute; */
  /* top: 35px; */
  /* font-size: 50px; */

}
 
.c-full-slider__bullet--container{
  /* position:relative; */
  height: 90px;
}
.c-full-slider__bullet--container .title{
  /* position:absolute; */
  /* left:10px; */
  /* -webkit-transform:translateX(-50%);
  -ms-transform:translateX(-50%);
  transform:translateX(-50%);
  font-size:12px; */
  /* line-height:215%; */
  /* color:#000; */
  /* width:110px; */
  /* text-align:center;
  opacity:1; */
  /* visibility:hidden; */
  /* z-index:0; */
  /* -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s */
  
}
/* .c-full-slider__bullet--container.js-active .title{
  top: 35px;
  opacity:1;
  visibility:visible
} */
/* @media screen and (max-width: 1050px){
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
  .c-full-slider__content{
    margin-top:60px
  }
  .c-full-slider__title{
    font-size:28px
  }
  .c-full-slider__desc{
    font-size:14px
  }
}
@media screen and (max-width: 1600px){
  .c-full-slider__nav{
    padding:0 80px;

  }
}
@media screen and (max-width: 1460px){
  .c-full-slider__nav{
    padding:0 30px;
    top: -200;
  }
}
@media screen and (max-width: 1300px){
  .c-full-slider__nav{
    display:none;
    top: -200;
  }
}
@media (min-width: 1280px) and (max-width: 1440px){
  .c-full-slider .c-full-slider__content{
    margin-top:-300;
  }
  .c-full-slider__caption{
   padding-top: 250px;
}
} 
  @media (max-width: 1024px){
  .c-full-slider .swiper-slide{
    -webkit-box-align:end;
    -ms-flex-align:end;
    align-items:flex-end
  }
  .c-full-slider__content{
    margin-top:0px;
    margin-bottom:10px
  }
  .c-full-slider__caption-text{
    max-width:500px
  }
  .c-full-slider__title{
    font-size:32px;
    margin-bottom:2px
  }
  .c-full-slider__actions .c-button{
    width:180px;
    height:45px;
    margin-bottom: 50px;
  }
  .c-full-slider__title{
    font-size:22px;
    margin-bottom: 80px;
  }
  .c-full-slider__actions .c-button{
    width:180px;
    height:45px;
    margin-bottom: 150px;
  }
  .c-full-slider__desc{
    font-size:18px;
    line-height:36px;
    margin-bottom: -180px;
  }
  .c-full-slider__bullet{
    height:80px;
    bottom:30px;
    z-index: auto;
  }
  .c-full-slider__bullet{
    margin:0 15px
  }
}  
 @media (max-width: 768px){
  .c-full-slider__title{
    font-size:13px;
    margin-bottom:20px
  }
  .c-full-slider__cat span{
    margin-bottom:25px
  }
  .c-full-slider__desc{
    font-size:16px;
    line-height:32px;
    color:#000
  }
  .c-full-slider__bullet{
    margin:0 10px;
    width:40px;
    height:40px; 
  } 
}  
  @media (max-width: 576px){
  .c-full-slider__title{
    font-size:22px;
    margin-bottom: -60px;
  }
  .c-full-slider__actions .c-button{
    width:180px;
    height:45px;
    margin-bottom: 150px;
  }
  .c-full-slider__desc{
    display:none
  }
  .c-full-slider__bullet{
    width:27px;
    height:27px
  }
  .c-full-slider__bullet svg{
    width:auto;
    height:auto
  }
  .c-full-slider__bullet:hover,.c-full-slider__bullet.js-active{
    width:40px;
    height:40px
  }
}  
  @media (max-width: 480px){
  .c-full-slider__actions{
    text-align:center;
    margin-top:30px
  }
  .c-full-slider__actions .c-button{
    font-size:13px;
    width:170px;
    height:40px
  }
  .c-full-slider__bullet{
    margin:0 8px;
    width:27px;
    height:27px
  }
  .c-full-slider__bullet svg{
    width:auto;
    height:auto
  }
  .c-full-slider__bullet:hover,.c-full-slider__bullet.js-active{
    width:40px;
    height:40px
  }
}
@media (max-width: 480px){
  .c-full-slider__bullet{
    margin:0 6px
  }
  .c-full-slider__bullet:hover,.c-full-slider__bullet.js-active{
    width:40px;
    height:40px
  }
}  
/* @media screen and (orientation: landscape) and (max-device-width: 900px){
   .c-animated-logo{
    display:none
  } 
  .c-full-slider__desc{
    display:none
  }
  .c-full-slider__title{
    font-size:20px
  }
  .c-full-slider__content{
    margin-bottom:120px
  }

  .c-full-slider__bullet--container.js-active .title{
    top:150px
  }
} */
 
.o-container {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto;
   
}
.o-container .h4 {
  text-decoration: none;
  color: #000;
}

@media (min-width: 991px) {
  .o-container {
    max-width: 1200px;
  }
  .o-container--large {
    max-width: 1230px;
  }
}
.o-container:after {
  display: block !important;
  clear: both !important;
  content: "" !important;
}
.o-container__wrapper {
  position: relative;
  z-index: 1;  
}
.o-container--page {
  padding-top: 60px;
  padding-bottom: 40px;
  
}
@media (max-width: 768px) {
  .o-container--page {
    padding-top: 40px;
  }
}
@media (max-width: 400px) { 
  .o-container--page {
    padding-top: 10px;
    width: 100%;
  }  
}

/*--------------------------------------------------------------
# About Lists
--------------------------------------------------------------*/
.about-lists {
	padding: 10px;
  }
  
  .about-lists .row {
	overflow: hidden;
  }
  
  .about-lists .content-item {
	padding: 10px;
	border-left: 1px solid #ff7e00;
	border-bottom: 1px solid #ff7e00;
	margin: -1px;
  }
  
  .about-lists .content-item span {
	display: block;
	font-size: 24px;
	font-weight: 400;
	color: #000;
  }
  
  .about-lists .content-item h4 {
	font-size: 28px;
	font-weight: 400;
	padding: 0;
	margin: 20px 0;
  }
  .about-lists .content-item h2 {
    color: #3D271A;
    }
  
  .about-lists .content-item p {
	color: #000;
	font-size: 15px;
	margin: 0;
	padding: 0;
  }
  
  /* @media (max-width: 768px) {
	.about-lists .content-item {
	  padding: 40px 0;
	}
  } */
  
  /* Özel Button */

  .c-special-button{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    padding:0 15px; 
    width: 200px;
    margin:0;
    height:50px;
    font:inherit;
    font-size: 11px;
    font-weight: 700;
    color:#3D271A;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    cursor:pointer;
    border-radius:3px;
    -webkit-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    transition:all 0.3s ease; 
    text-decoration:none;
    border:1px solid #ff7e00
  }
  .c-special-button:hover{
    color:#000;
    background:#ff7e00;
    border:1px solid #ff7e00;
    text-decoration:none
  }
  .c-special-button--md{
    width:225px
  }
  .c-special-button--border{
    border:1px solid #ff7e00;
    background-color:transparent;
    color:#ff7e00
  }
  .c-special-button--white{
    border-color:#fff;
    color:#fff
  }
  .c-special-button--white:hover{
    border:1px solid #ff7e00;
    background-color:#fff
  }
  .c-special-button--secondary{
    background:#3D271A
  }
 
  .c-special-button_actions { 
    text-align: center;
    top: -50px;
  }
  .c-special-button .c-button {  
    font-size: 14px;
    font-weight: 500;
  }

  .c-button_actions {
    text-align: center;
    top: -50px;
  }
  .c-button_actions .c-button {
    font-size: 14px;
    font-weight: 500;
  }


/* Tanıtım Section */

.c-section-title__sub-title {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 8px;
  color: #ff7e00;
  margin-bottom: 50px;
  margin: auto;
}
.c-section-title__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 80px;
}
.c-section-title__main--title {
  width: 50%;
  padding-right: 50px;
  font-size: 45px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 0;
  color:#3D271A;
}
.c-section-title__main--full {
  width: 100%;
}
.c-section-title__main--desc {
  width: 50%;
  font-size: 25px;
  font-weight: 300;
  margin-top: 15px;
  color:#3D271A;
}
/* @media (max-width: 1200px) {
  .c-section-title {
    margin-bottom: 80px;
  }
  .c-section-title__sub-title {
    margin-bottom: 25px;
  }
  .c-section-title__main--title {
    padding-right: 30px;
    font-size: 38px;
    line-height: 50px;
  }
}
@media (max-width: 768px) {
  .c-section-title {
    margin-bottom: 50px;
  }
  .c-section-title__sub-title {
    margin-bottom: 20px;
  }
  .c-section-title__main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .c-section-title__main--title {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 50px;
  }
  .c-section-title__main--desc {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .c-section-title {
    margin-bottom: 40px;
  }
  .c-section-title__main--title {
    font-size: 30px;
    line-height: 40px;
  }
  .c-section-title__main--desc {
    width: 100%;
  }
} */

/* grid ölçekleme */
.kapsayici {
  display: grid;
  grid-gap: 5px; 
}

.kapsayici--fill {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.kapsayici--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.grid-ogesi { 
  padding: 20px;
}


/* hizmetler faaliyet */

.c-services {
  border-bottom: 1px solid #e5e5e5;
  padding:  0;
}
.c-services .swiper-wrapper {
  height: 550px;
}
.c-services__wrapper {
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
}
.c-services__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;  
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  /* height: initial !important; */
  padding-right: 1px;   
}
.c-services__item:hover .c-services__item--title {
  opacity: 1;
}
.c-services__item:hover .c-services__item--image-container img {
  margin-left: -20px;
}
.c-services__item--wrapper {
  position: relative;
}
.c-services__item-container {
  margin-bottom: 11px;
}
.c-services__item--btn { 
   position: relative;
   width: 240px;
  height: 25px;
  left: 60px;
  top: -40px;  
   
}

.c-services__item--title {
  display: -webkit-box;
  display: -ms-flexbox; 
  position: relative;
  font-size: 30px;
  font-weight: 500;
  line-height: 105px;
  opacity: 0.1;
  color: #052b49;
  width: 105px;
  height: 100%;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.c-services__item--title a {
  color: #052b49;
}
.c-services__item--title span {
  display:block;
  -webkit-transform:rotate(-90deg);
  -ms-transform:rotate(-90deg);
  transform:rotate(-90deg);
  position:absolute;
  left:-115px;
  bottom:85px;
  width:237px;
  height:85px;
  white-space:nowrap;
  top: -250px;
  
}
.c-services__item--title span.medium {
  font-size: 60px;
}
.c-services__item--title span.medium-1 {
  font-size: 90px;
}
.c-services__item--title span.small {
  font-size: 52px;
}
.c-services__item--image {
  width: calc(200% - 20px);
  overflow: hidden;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.c-services__item--image-container img {
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  max-height: 400px;
  max-width: initial;
}
.c-services__nav {
  position: absolute;
  top: calc(50% - 17px);
  left: -300px;
  width: calc(100% + 340px);
  height: 45px; 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  z-index: 999;
}
.c-services__nav svg {
  fill: #ddd;
  width: 34px;
  height: 34px;
}
.c-services__nav--next,
.c-services__nav--prev {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 78px;
  height: 78px;
  border-radius: 50%; 
}
.c-services__nav--next:focus,
.c-services__nav--prev:focus {
  outline: none;
}
.c-services__nav--next svg,
.c-services__nav--prev svg {
  width: 13px;
  height: 13px;
}
.c-services__nav--next svg {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.c-services__nav--prev svg {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c-services__actions {
  text-align: center;
}
.c-services__actions .c-button {
  width: 200px;
  font-size: 14px;
  font-weight: 500;
}
/* @media screen and (max-width: 1550px) {
  .c-services__nav {
    left: -100px;
    width: calc(100% + 200px);
  }
}
@media screen and (max-width: 1380px) {
  .c-services__nav {
    left: -75px;
    width: calc(100% + 150px);
  }
  .c-services__nav--next,
  .c-services__nav--prev {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 1340px) {
  .c-services__nav {
    display: none;
  }
}
.c-services__pagination {
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
}
.c-services__pagination .swiper-pagination-bullet {
  margin: 2px;
}
.c-services__actions {
  text-align: center;
}
.c-services__actions .c-button {
  width: 200px;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .c-services .c-col__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: initial;
    flex-flow: initial;
  }
  .c-services .c-bg-border .c-col:nth-child(2),
  .c-services .c-bg-border .c-col:nth-child(4) {
    border-left: 0;
  }
}
@media (max-width: 768px) {
  .c-services__pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 576px) {
  .c-services .c-bg-border .c-col:nth-child(3) {
    border-left: 0;
  }
} */

/* footer */

.o-footer {
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}
.o-footer__top {
  padding: 60px 15px 30px 15px;
  display: block;
  position: relative;
}
.o-footer__bottom {
  position: relative;
  padding: 35px 15px;
  font-size: 14px;
  color: #80909c;
  border-top: 1px solid #e5e5e5;
}
.o-footer__bottom .o-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.o-footer__bottom .o-container::after {
  display: none !important;
}
.o-footer__bottom-nav a {
  color: #80909c;
  display: inline-block;
  margin-left: 20px;
}
.o-footer__bottom-nav a:hover {
  color: #0083bf;
}
.o-footer__bottom-nav a:after {
  content: "-";
  margin-left: 20px;
}
.o-footer__bottom-nav a:last-child:after {
  display: none;
}
.o-footer__bottom-icon svg {
  width: 55px;
  height: 14px;
}
.o-footer__bottom-copyright {
  font-size: 12px;
  font-weight: 300;
}
.o-footer__title {
  font-size: 13px;
  font-weight: 500;
  color: #052b49;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 35px;
}
.o-footer__title svg {
  display: none;
  width: 10px;
  height: 10px;
  fill: #052b49;
}
.o-footer p {
  font-size: 14px;
  line-height: 26px;
  color: #80909c;
}
.o-footer__column {
  float: left;
  width: calc(17% - 24px);
  margin-right: 15px;
}
.o-footer__column:last-child {
  margin-right: 0;
}
.o-footer__logo {
  margin-bottom: 50px;
}
.o-footer__nav {
  margin-bottom: 50px;
}
.o-footer__nav:last-child {
  margin-bottom: 0;
}
.o-footer__nav__item {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  font-size: 14px;
  color: #80909c;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.o-footer__nav__item:hover {
  color: #0083bf;
  text-decoration: none;
}
.o-footer__nav__item:hover svg {
  fill: #0083bf;
}
.o-footer__nav__item span {
  display: inline-block;
  vertical-align: middle;
}
.o-footer__nav__item svg {
  fill: #80909c;
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
/* @media (max-width: 1200px) {
  .o-footer__column {
    margin-right: 15px;
  }
}
@media (max-width: 991px) {
  .o-footer__top {
    padding: 30px 0 0 0;
  }
  .o-footer__column {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }
  .o-footer__column:last-child .o-footer__nav:last-child .o-footer__title {
    border-bottom: 0;
  }
  .o-footer__logo {
    text-align: center;
    margin-bottom: 20px;
  }
  .o-footer__bottom {
    padding: 35px 15px 30px 15px;
  }
  .o-footer__bottom .o-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .o-footer__bottom-copyright,
  .o-footer__bottom-nav {
    text-align: center;
    margin-bottom: 15px;
  }
  .o-footer__nav {
    margin-bottom: 0;
  }
  .o-footer__nav__item {
    margin-bottom: 15px;
  }
  .o-footer__nav__item svg {
    margin-right: 5px;
  }
  .o-footer__nav__item:last-child {
    margin-bottom: 0;
  }
  .o-footer__nav__item--single {
    margin-bottom: 0;
  }
  .o-footer__nav .o-footer__title svg {
    display: block;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }
  .o-footer__nav.is-open .o-footer__title svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .o-footer__nav .o-footer__title {
    margin-bottom: 0;
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
  }
  .o-footer__nav__list {
    position: relative;
    display: none;
    padding: 20px 0;
  }
  .o-footer__nav__list a {
    padding: 3px 9px;
  }
}
@media (max-width: 768px) {
  .o-footer__top {
    padding: 30px 0 0 0;
  }
  .o-footer__bottom {
    padding: 30px 15px 50px 15px;
  }
  .o-footer__bottom-nav {
    float: none;
    text-align: center;
    width: 100%;
  }
  .o-footer__bottom-nav a {
    margin-bottom: 10px;
  }
  .o-footer .o-footer__nav__list--social .o-footer__nav__item {
    display: inline !important;
  }
  .o-footer__whatsapp {
    top: -25px;
  }
  .o-footer__whatsapp a {
    width: 50px;
    height: 50px;
  }
  .o-footer__whatsapp a svg {
    width: 24px;
    height: 24px;
  }
  .o-footer__copyright {
    position: absolute;
    bottom: 20px;
    float: none;
    left: 0;
    right: 0;
    text-align: center;
  }
} */

.o-container--page ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.o-container--page ul li {
  padding-left: 30px; 
  background-size: 9px;
  margin-bottom: 20px;
}

/* sözleşme ara */
.c-dl__item{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  padding:35px;
  border:1px solid #ff7e00;
  border-top:3;
  background-color:#fff
}
.c-dl__item:first-child{
  border-top:1px solid #E5E5E5
}
.c-dl__item .left{
  width:100%;
  height:50px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
.c-dl__item .left img{
  max-height:100px
}
.c-dl__item .right{
  width:calc(100% - 1px);
  padding-left:10px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.c-dl__item .right .col{
  width:30px
}
.c-dl__item .right .col .title{
  font-size:17px;
  font-weight:700
}

/* Hizmetler */
.c-news-item{
  margin-bottom:150px
}
.c-news-item:last-child{
  margin-bottom:100px
}
.c-news-item--reverse .c-news-item__wrapper{
  -webkit-box-orient:horizontal;
  -webkit-box-direction:reverse;
  -ms-flex-direction:row-reverse;
  flex-direction:row-reverse
}
.c-news-item--reverse .c-news-item__right{
  padding-right:80px;
  padding-left:0
}
.c-news-item--small-image .c-news-item__left{
  padding-right:80px
}
.c-news-item--small-image .c-news-item__right{
  padding-left:0
}
.c-news-item__wrapper{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.c-news-item__left{
  width:50%
}
.c-news-item__image img{
  width:100%
}
.c-news-item__right{
  width:50%;
  padding-left:80px
}
.c-news-item__date{
  font-size:12px;
  margin-bottom:10px;
  color:#80909C
}
.c-news-item__date span{
  margin-right:5px
}
.c-news-item__title{
  font-size:26px;
  font-weight:700;
  margin-bottom:30px
}
.c-news-item__title--small{
  font-size:20px
}
.c-news-item p{
  font-size:16px;
  line-height:27px
}
.c-news-item p.small{
  font-size:14px;
  line-height:24px
}
.c-news-item p:last-child{
  margin-bottom:0
}
.c-news-item__actions{
  margin-top:30px
}
.c-news-item__actions .c-button{
  width:50%;
  height:35px;
  font-size:12px;
  font-weight:500
}
/* @media (max-width: 991px){
  .c-news-item{
      margin-bottom:100px
  }
  .c-news-item:last-child{
      margin-bottom:60px
  }
  .c-news-item__left{
      width:40%
  }
  .c-news-item__right{
      width:60%;
      padding-left:50px
  }
  .c-news-item--small-image .c-news-item__left{
      width:50%;
      padding-right:50px
  }
  .c-news-item--small-image .c-news-item__right{
      width:50%;
      padding-left:0
  }
  .c-news-item--reverse .c-news-item__right{
      padding-right:50px
  }
}
@media (max-width: 768px){
  .c-news-item{
      margin-bottom:80px
  }
  .c-news-item:last-child{
      margin-bottom:30px
  }
  .c-news-item__wrapper{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  .c-news-item__left{
      width:100%
  }
  .c-news-item__image{
      margin-bottom:30px
  }
  .c-news-item__right{
      width:100%;
      padding-left:0
  }
  .c-news-item--small-image .c-news-item__left{
      width:100%;
      padding-right:0
  }
  .c-news-item--small-image .c-news-item__right{
      width:100%;
      padding-left:0
  }
  .c-news-item--reverse{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  .c-news-item--reverse .c-news-item__wrapper{
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column
  }
  .c-news-item--reverse .c-news-item__right{
      padding-right:0
  }
}
  */
/* Kutucukları  */

.c-box{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;  
}
.c-box--list{
    margin-left:-90px
}
.c-box--small{
    position:relative;
    margin-left:0
}
.c-box__item{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-transition:all 0.3s;
    text-decoration: none;
    -o-transition:all 0.3s;
    transition:all 0.3s
    
}
.c-box__item--big:hover{
    text-decoration:none;
    background:#F9B224
}
.c-box__item--big:hover .c-box__text{
    color:#3D271A;
    margin-top:-20px
}
.c-box__item--big:hover .c-box__img{
    opacity:0
}
.c-box__item--list{
    width:200px;
    height:200px;
    /* border:1px solid #A1BCD1; */
    border-radius:10px;
    margin-left:90px;
    margin-bottom:100px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    padding:0 15px
}
.c-box__item--small{
    position:relative;
    width:245px;
    height:260px;
    margin-left:0;
    margin-right:63px;
    margin-bottom:0;
    margin-top:100px;
    margin-bottom:100px
}
.c-box__item--small:last-child{
    margin-right:0
}
.c-box__item--small:last-child:after{
    display:none
}
.c-box__item--small:after{
    position:absolute;
    content:'';
    /* background:url("/assets/img/uploads/png-icon/list-icon.png"); */
    width:13px;
    height:14px;
    right:-17%;
    top:50%
}
.c-box__img{  
    -webkit-transition:all 0.3s;
    -o-transition:all 0.3s;
    transition:all 0.3s
}
.c-box__text{
    color:#0083BF;
    text-align:center;
    font-size:20px;
    margin-top:10px;
    -webkit-transition:all 0.3s;
    -o-transition:all 0.3s;
    transition:all 0.3s
}
/* @media (max-width: 1200px){
    .c-box--list{
        margin-left:-40px
    }
    .c-box__item--list{
        margin-left:40px;
        margin-bottom:50px
    }
    .c-box__item--small{
        margin-left:0;
        margin-right:50px
    }
}
@media (max-width: 991px){
    .c-box--list{
        margin-left:-20px
    }
    .c-box--small{
        margin:50px 0
    }
    .c-box__item--list{
        width:300px;
        height:320px;
        margin-left:20px
    }
    .c-box__item--small{
        width:45%;
        margin:0;
        margin:10px 2.5%
    }
    .c-box__item--small:after{
        display:none
    }
}
@media (max-width: 768px){
    .c-box__item--list{
        width:100%
    }
} */
.c-col{
    width:100%
}
.c-col__row{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
.c-col-4{
    width:calc(100% / 4)
}
.c-col-3{
    width:calc(100% / 3)
}
.c-col-2{
    width:calc(100% / 2)
}
@media (max-width: 768px){
    .c-col__row{
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
        -ms-flex-flow:row wrap;
        flex-flow:row wrap
    }
    .c-col-4{
        width:calc(100% / 2);
        float:left
    }
}
/* columnd */

.c-col {
  width: 100%;
}
.c-col__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.c-col-4 {
  width: calc(100% / 4);
}
.c-col-3 {
  width: calc(100% / 3);
}
.c-col-2 {
  width: calc(100% / 2);
}
@media (max-width: 768px) {
  .c-col__row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .c-col-4 {
    width: calc(100% / 2);
    float: left;
  }
}




.tabs {
  display: flex;
  justify-content: space-between;
}

.tabs button {
  background-color: lightgray;
  border: none;
  color: #888888;
  cursor: pointer;
  padding: 25px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease-out;
}


.c-tabs__nav ul{
  list-style:none;
  padding:0;
  margin:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}
.c-tabs__nav ul li{
  margin-right:50px
}
.c-tabs__nav ul li:last-child{
  margin-right:0
}
.c-tabs__nav ul li a{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:70px;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  color:#0083BF;
  font-size:11px;
  font-weight:700;
  border-bottom:2px solid transparent
}
.c-tabs__nav ul li.js-active a{
  color:#0083BF;
  border-bottom:2px solid #0083BF
}
.c-tabs__content{
  margin-top:40px
}
.c-tabs__tab-content{
  display:grid;
}
.c-tabs__tab-content.js-active{
  display:block
}
.c-tabs--page .c-tabs__nav{
  margin-bottom:60px
}
.c-tabs--page .c-tabs__nav ul li{
  width:25%;
  background:none;
  padding:0;
  margin:0;
  float:left
}
.c-tabs--page .c-tabs__nav ul li a{
  height:60px;
  font-size:17px;
  font-weight:500;
  color:#80909C;
  border-top:1px solid #E5E5E5;
  border-bottom:1px solid #E5E5E5;
  -webkit-transition:all ease .3s;
  -o-transition:all ease .3s;
  transition:all ease .3s
}
.c-tabs--page .c-tabs__nav ul li a:hover{
  text-decoration:none
}
.c-tabs--page .c-tabs__nav ul li:first-child a{
  border-left:1px solid #E5E5E5
}
.c-tabs--page .c-tabs__nav ul li.js-active a{
  background-color:#0083BF;
  color:#fff;
  border-top:1px solid #0083BF;
  border-bottom:1px solid #0083BF
}
.c-tabs--bilgi .c-tabs__nav ul{
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
}
/* @media (max-width: 768px){
  .c-tabs--page .c-tabs__nav ul{
      -webkit-box-orient:horizontal;
      -webkit-box-direction:normal;
      -ms-flex-flow:row wrap;
      flex-flow:row wrap
  }
  .c-tabs--page .c-tabs__nav ul li{
      width:50%
  }
  .c-tabs--page .c-tabs__nav ul li:nth-child(1) a,.c-tabs--page .c-tabs__nav ul li:nth-child(2) a{
      border-bottom:0
  }
  .c-tabs--bilgi .c-tabs__nav ul li:nth-child(1) a,.c-tabs--bilgi .c-tabs__nav ul li:nth-child(2) a{
      border-bottom:1px solid #E5E5E5
  }
}
@media (max-width: 480px){
  .c-tabs--page .c-tabs__nav ul li a{
      font-size:14px
  }
} */


/* brands */

.c-brands { 
  background-size: 25px;
  padding: 5px 0;
}
.c-brands__wrapper {
  position: relative;
  z-index: 1;
}
.c-brands__title {
  text-align: center;
}
.c-brands__title--head {
  font-size: 23px;
  font-weight: 400;
  color: #052b49;
  margin-bottom: 15px;
}
.c-brands__title--desc {
  font-size: 19px;
  color: #80909c;
  margin-bottom: 0;
}
.c-brands__items {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 70px;
}
.c-brands__item {
  border-right: 1px solid #e5e5e5;
  height: 110px;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.c-brands__item:first-child {
  border-left: 1px solid #e5e5e5;
}
.c-brands__item img {
  max-width: 180px;
  width: 100%;
}
.c-brands .c-tabs__nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.c-brands .c-tabs__nav ul li {
  margin-right: 0;
  width: calc(100% / 8);
}
.c-brands .c-tabs__nav ul li:first-child a {
  border-left: 1px solid #e5e5e5;
}
.c-brands .c-tabs__nav ul li a {
  height: 120px;
  color: #000;
  background-color: #fff;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 0;
  border-right: 1px solid #e5e5e5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.c-brands .c-tabs__nav ul li a svg {
  fill: #0083bf;
  max-width: 41px;
  max-height: 32px;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.c-brands .c-tabs__nav ul li a span {
  margin-top: 15px;
}
.c-brands .c-tabs__nav ul li:hover a,
.c-brands .c-tabs__nav ul li.js-active a {
  color: #fff;
  background-color: #0083bf;
  border-bottom: 0;
  text-decoration: none;
}
.c-brands .c-tabs__nav ul li:hover a svg,
.c-brands .c-tabs__nav ul li.js-active a svg {
  fill: #fff;
}
.c-brands .c-tabs__content {
  margin-top: 70px;
}
.c-brands .c-tabs__tab-content {
  display: none;
}
.c-brands .c-tabs__tab-content.js-active {
  display: block;
}
/* @media (max-width: 991px) {
  .c-brands .c-tabs__nav ul li a {
    height: 100px;
  }
  .c-brands .c-tabs__nav ul li a svg {
    max-width: 36px;
    max-height: 25px;
  }
  .c-brands .c-tabs__nav ul li a span {
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .c-brands__item {
    border-right: 0;
    border-left: 0;
    border: none;
    margin: 15px 0;
  }
  .c-brands__item:first-child {
    border-left: 0;
  }
  .c-brands .c-tabs__nav ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    border: none;
    border-bottom: 0;
  }
  .c-brands .c-tabs__nav ul li {
    width: calc(100% / 4);
    float: left;
  }
  .c-brands .c-tabs__nav ul li:first-child a {
    border-left: 0;
  }
  .c-brands .c-tabs__nav ul li a {
    border-bottom: 1px solid #e5e5e5;
  }
  .c-brands .c-tabs__content {
    margin-top: 50px;
  }
}
@media (max-width: 480px) {
  .c-brands .c-tabs__nav ul li a {
    height: 80px;
    font-size: 13px;
  }
  .c-brands .c-tabs__nav ul li a svg {
    max-width: 30px;
    max-height: 20px;
  }
  .c-brands .c-tabs__nav ul li a span {
    margin-top: 10px;
  }
} */


 

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* İkame Araç */
 .ikame_arac{
  position: relative;
  margin: auto;
  width: 90%;
  height: 100%;
  padding-bottom: 50px;
   
 }
.ikame_arac_move {
  width: 50px;
  height: 300px;
}
.ikame_arac_go{
  position: absolute;
  right: 0;
  top: 50px;
   /* width: 150px;
  height: 900px; */
} 
.Kurumsal_image {
  position: relative;
  margin: auto;
  width: 100%;
  left: -180px;

}

.IletisimContainer 
{
  background-color: #ff7e00;
  color:#fff;
  height: 500px;
  border-radius: 12px;
  padding: 30px;

}
.IletisimContainer h1
{
  font-size: 30px;

}
.IletisimContainer .span
{
  color:#fff;
}
 

footer {
  bottom: 0;
  background: #ff7e00;
  color: #000;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Bottom - copyright and legal stuff */

footer .info {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ff7e00;
}

footer .info .legal {
  display: flex;
  flex-wrap: nowrap;
  text-decoration: none;
}

footer .info .legal > a {
  margin-right: 15px; 
  text-decoration: none;
  color: #000;
}

/* Middle - social media links */

footer .social {
  padding: 4.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

footer .social li {
  font-size: 1.4rem;
  margin: 2px 8px;
  cursor: pointer; 
  list-style: none;
}

footer .social:before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background: rgba(255, 255, 255, 0.192);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Top - pages, email newsletter form ... */

footer .top {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

footer .top .pages {
  display: flex;
  text-decoration: none;
  color: #fff;
}

footer .top .pages ul {
  margin: 20px; 
  text-decoration: none;
  color: #000;
}

footer .top .pages ul li {
  margin: 5px;
  text-decoration: none;
  color: #fff;
  text-align: left;
  
}
footer .top .pages ul li a{
  margin: 5px;
  text-decoration: none;
  color: #fff;
  text-align: left;
  
}

footer  h3 {
  margin-bottom: 10px;
  left: 0px;
  
}
footer .newsletter form {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  footer .top {
    flex-direction: column;
  }
  footer .top .newsletter {
    padding-top: 1rem;
  }
  footer .top .pages ul {
    margin: 10px;
    text-decoration: none;
  color: #000;
  }
}

@media screen and (max-width: 600px) {
  footer .top .pages {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  footer .info {
    flex-direction: column;
  }

  footer .info > .copyright {
    margin-top: 10px;
  }
}

@media screen and (max-width: 380px) {
  footer .top .pages {
    justify-content: center;
    flex-direction: column;
  }

  footer .info .legal {
    justify-content: center;
  }

  footer .info .legal a {
    margin: 0;
    text-align: center;
  }
}

.swiper-button-next,
.swiper-button-prev {
    background-color: #ff7e00;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 25px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
}


.map-container {
  height: 90%;
  width: 90%;
}

.boxsol{ 
  height: 250px;
  margin: 5px; 
  float:initial;
  width: 80%;
  position: relative;
   
}
  
#first{ 
  right: 20%;
} 
  
#second{
  width: 350px;
  position:absolute; 
  top:100px;
  right: 10%;
  align-items: end;
}
  
#third{ 
  position: relative;
  width: 10px;
  height: 250px;
  left:  -1%;
}

.boxsag{
  width: 45%;
  height: 250px;
  margin: 5px;   
  float: initial;
  position: relative;
}
#firstsag{
  left: 60%;   
}
  
#secondsag{  
  width: 350px;
  position:absolute; 
  top:120px;
  left: 51%;
  align-items: start; 
}
  
#thirdsag{
  right: -60%;
  position: relative;
  width: 350px;
  height: 250px; 
} 

.contact-ss {
  box-shadow: 0px 0px 10px #00000024;
    border-radius: 5px;
    overflow: hidden;
    padding: 25px;
}
.single-info{
  background: #ff7e00;
    padding: 40px 60px;
    height: 180px;
    border-radius: 10px;
    position: relative;
    color: #fff;
}
 
.mss {
  font-size: 12px;
}
.mss h2 {
  font-size: 20px;
}

.our-contact {
  padding: 60px;
  background-image: linear-gradient(145deg, #f9b224 0%, #ff7e00 100%);
  border-radius: 15px;
  margin-right: 30px;
  margin-left: 30px;
  box-shadow: 18px 18px 40px 0px rgb(198 81 36 / 30%);
}
 
.iletisim h1,.iletisim h2,.iletisim h3,.iletisim h4,.iletisim h5,.iletisim h6{
  margin-top:30px;
  margin-bottom:20px;
  color:#fff; 
  text-decoration: none;
  font-size: 950;
  padding-top: 15px;
}

.contact-info .info-text {
  padding-top:30px;
  font-weight: 500;
}